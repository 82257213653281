import React, { FC, PropsWithChildren } from 'react'

import { WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'
import { get } from 'lodash'

import { useAppContext } from 'app/context'

import styles from './ColorsUsageDetails.module.scss'

export const ColorsUsageDetails: FC<PropsWithChildren<{ path: string; title: string }>> = ({
  path,
  title,
  children,
}) => {
  const appContext = useAppContext()
  const color = get(appContext.createTheme, path, '')

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <div className={styles.preview} style={{ backgroundColor: color }} />
        <WppTypography type="s-body" className={styles.colorText}>
          {color}
        </WppTypography>
        <WppTypography type="s-body">{title}</WppTypography>
      </div>
      <WppDivider className={styles.divider} />
      {children}
    </div>
  )
}
