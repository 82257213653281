import React, { useContext } from 'react'

import { Theme } from '@platform-ui-kit/components-library'

import { ThemeFont } from 'pages/manage-theme/components/Typography/types'
import { CreateTheme, CustomFont, MayBeNull, FontTypes } from 'pages/manage-theme/types'
import { getInitialValues } from 'pages/manage-theme/utils'

export interface IAppContext {
  createTheme: CreateTheme
  setCreateTheme: (createTheme: CreateTheme) => void
  savedCreateTheme: CreateTheme | null
  setSavedCreateTheme: (createTheme: CreateTheme | null) => void
  usageDetails: any
  setUsageDetails: (usageDetails: any) => void
  jsonData: MayBeNull<Theme>
  setJsonData: (jsonData: MayBeNull<Theme>) => void
  customFont: MayBeNull<CustomFont>
  setCustomFont: (customFont: MayBeNull<CustomFont>) => void
  secondaryFont: MayBeNull<ThemeFont>
  setSecondaryFont: (customFont: MayBeNull<ThemeFont>) => void
  secondaryCustomFont: MayBeNull<CustomFont>
  setSecondaryCustomFont: (customFont: MayBeNull<CustomFont>) => void
  previewId: MayBeNull<string>
  setPreviewId: (previewId: MayBeNull<string>) => void
  fontTypes: FontTypes
  setFontTypes: (fontTypes: FontTypes) => void
}

export const AppContext = React.createContext<IAppContext>({
  createTheme: getInitialValues(),
  setCreateTheme: () => {},
  savedCreateTheme: null,
  setSavedCreateTheme: () => {},
  usageDetails: null,
  setUsageDetails: () => {},
  jsonData: null,
  setJsonData: () => {},
  customFont: null,
  setCustomFont: () => {},
  secondaryFont: null,
  setSecondaryFont: () => {},
  secondaryCustomFont: null,
  setSecondaryCustomFont: () => {},
  previewId: null,
  setPreviewId: () => {},
  fontTypes: {},
  setFontTypes: () => {},
})

export const useAppContext = () => {
  return useContext(AppContext)
}
