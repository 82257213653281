import { useState, useEffect } from 'react'

export const useToast = () => {
  const [toastRef, setToastRef] = useState<HTMLWppToastContainerElement | null>()

  const showToast = (config: any) => {
    toastRef?.addToast(config)
  }

  useEffect(() => {
    setToastRef(document.querySelector<HTMLWppToastContainerElement>('.wpp-toast-container'))
  }, [])

  return {
    showToast,
  }
}
