export const pagePreviewTabs = [
  { id: 'home', title: 'Home screen' },
  { id: 'form', title: 'Form' },
  { id: 'table', title: 'Table' },
  { id: 'data-viz', title: 'Data Visualization' },
  { id: 'components', title: 'Components' },
]

export const pagePreviewBreadcrumbs = [
  { label: 'ACME' },
  { label: 'US' },
  { label: 'Umbrella' },
  { label: 'Targeting APP' },
]
