import React, { FC, PropsWithChildren } from 'react'

import { WppCard, WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'
import { getBoxShadowStyleForValues } from 'pages/manage-theme/utils'

import styles from './BoxShadowUsageDetails.module.scss'

export const BoxShadowUsageDetails: FC<PropsWithChildren<{ title: string; type: string }>> = ({
  title,
  type,
  children,
}) => {
  const appContext = useAppContext()
  const boxShadow = getBoxShadowStyleForValues(appContext.createTheme, type)

  const boxShadowStyle = {
    '--custom-box-shadow': boxShadow,
  }

  return (
    <div className={styles.body}>
      <WppTypography type="s-strong" className={styles.header}>
        {title}
      </WppTypography>
      <WppCard className={styles.card} variant="secondary">
        <WppCard className={styles.preview} style={boxShadowStyle} />
      </WppCard>
      <WppDivider className={styles.divider} />
      {children}
    </div>
  )
}
