import React from 'react'

import { WppIconCodeView, WppIconEdit } from '@platform-ui-kit/components-library-react'

import styles from './HomePage.module.scss'

export enum CARD_ACTIONS {
  CREATE_THEME = 'CREATE_THEME',
  IMPORT_JSON_FILE = 'IMPORT_JSON_FILE',
}

export interface Card {
  title: string
  description: string
  icon: JSX.Element
  action: CARD_ACTIONS
}

export const cards = [
  {
    title: 'Create new theme',
    description: 'Build your brand theme starting with an empty template',
    icon: <WppIconEdit className={styles.wppIcon} />,
    action: CARD_ACTIONS.CREATE_THEME,
  },
  {
    title: 'Import JSON',
    description: 'Upload a .json file to start modifying your existing theme',
    icon: <WppIconCodeView className={styles.wppIcon} />,
    action: CARD_ACTIONS.IMPORT_JSON_FILE,
  },
]
