export const HINTS = [
  'Upload all the desired font styles at once',
  'The uploaded font files must belong to the same font family',
  'Use the font family name and weight in the file name',
]

export const FONT_WEIGHT_NAMES: Record<string, string> = {
  100: 'Thin',
  200: 'Extra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black',
  950: 'Extra Black',
}

export const FONT_WEIGHTS = [
  'Thin',
  'Extra-Light',
  'Ultra-light',
  'Light',
  'Normal',
  'Regular',
  'Medium',
  'Semi-bold',
  'Demi-bold',
  'Bold',
  'Extra-bold',
  'Ultra-bold',
  'Black',
  'Heavy',
]

export const FONT_STYLES = ['Regular', 'Italic', 'Oblique', 'Condensed', 'Wide', 'Extended', 'Expanded']

export const themesFonts = [
  {
    name: 'Inter',
    link: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap',
  },
  {
    name: 'graphie',
    link: 'https://use.typekit.net/dav7aoj.css',
  },
  {
    name: 'Mulish',
    link: 'https://public-assets.os.wpp.com/fonts/mulish/index.css',
  },
  {
    name: 'Poppins',
    link: 'https://public-assets.os.wpp.com/fonts/poppins/index.css',
  },
  {
    name: 'Open Sans',
    link: 'https://public-assets.os.wpp.com/fonts/open-sans/index.css',
  },
  {
    name: 'halyard-display',
    link: 'https://use.typekit.net/gsk0gei.css',
  },
  {
    name: 'aktiv-grotesk',
    link: 'https://use.typekit.net/dav7aoj.css',
  },
  {
    name: 'Ogilvy Sans',
    link: 'https://public-assets.os.wpp.com/fonts/ogilvy-sans/index.css',
  },
  {
    name: 'Unity',
    link: 'https://public-assets.os.wpp.com/fonts/unity/index.css',
  },
  {
    name: 'Snowflake Sans',
    link: 'https://public-assets.os.wpp.com/fonts/snowflake-sans/index.css',
  },
  {
    name: 'Roboto',
    link: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap',
  },
  {
    name: 'WTGothic',
    link: 'https://public-assets.os.wpp.com/fonts/wt-gothic/index.css',
  },
]
