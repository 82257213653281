import React, { useEffect, useState, useCallback } from 'react'

import { SelectChangeEventDetail, ToggleChangeEvent } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppIconUpload,
  WppListItem,
  WppSelect,
  WppTypography,
  WppCard,
  WppDivider,
  WppToggle,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { upperFirst } from 'lodash'
import { loadStyleSheet } from 'utils'

import { useAppContext } from 'app/context'
import { UploadFontModal } from 'pages/manage-theme/components/Typography/components/UploadFontModal/UploadFontModal'
import { TYPOGRAPHY_TYPES_SECTIONS } from 'pages/manage-theme/consts'
import { CreateTheme } from 'pages/manage-theme/types'

import { TypographyTypeSection } from './components/TypographyTypeSection/TypographyTypeSection'
import { themesFonts } from './consts'
import { ThemeFont } from './types'
import styles from './TypographySection.module.scss'

export const TypographySection: React.FC = () => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()
  const { setCreateTheme, setSecondaryFont, createTheme, customFont, secondaryFont, secondaryCustomFont } = appContext

  const [selectedFontName, setSelectedFontName] = useState<string>(createTheme.font.family || themesFonts[0].name)
  const [selectedSecondaryFontName, setSelectedSecondaryFontName] = useState<string>(
    appContext?.secondaryFont?.name || '',
  )
  const [isSecondaryFontEnabled, setIsSecondaryFontEnabled] = useState<boolean>(!!secondaryFont)
  const [isUploadFontModalOpen, setIsUploadFontModalOpen] = useState<boolean>(false)
  const [fontType, setFontType] = useState<'primary' | 'secondary'>('primary')

  const handleSelectChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    setSelectedFontName(event.detail.value as string)
  }

  const handleSecondarySelectChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    setSelectedSecondaryFontName(event.detail.value as string)
  }

  const handleSecondaryFontSwitchChange = (event: CustomEvent<ToggleChangeEvent>) => {
    setIsSecondaryFontEnabled(event.detail.checked)

    if (!event.detail.checked) {
      setSelectedSecondaryFontName('')
      appContext.setSecondaryFont(null)
    }
  }

  const getFullFontsList = useCallback(() => {
    const fontsList = [...themesFonts]

    if (customFont) {
      fontsList.push({ name: customFont.name, link: customFont.url })
    }

    if (secondaryCustomFont) {
      fontsList.push({ name: secondaryCustomFont.name, link: secondaryCustomFont.url })
    }

    return fontsList
  }, [customFont, secondaryCustomFont])

  useEffect(() => {
    const selectedFont: ThemeFont | undefined = getFullFontsList().find(font => font.name === selectedFontName)

    if (!selectedFont) return

    loadStyleSheet(selectedFont.link)

    setCreateTheme({
      ...formik.values,
      font: { family: selectedFont.name, url: selectedFont.link },
      name: createTheme.name,
    })
  }, [createTheme.name, formik.values, selectedFontName, setCreateTheme, getFullFontsList])

  useEffect(() => {
    const selectedFont: ThemeFont | undefined = getFullFontsList().find(font => font.name === selectedSecondaryFontName)

    if (!selectedFont) return

    loadStyleSheet(selectedFont.link)

    setSecondaryFont(selectedFont)
  }, [selectedSecondaryFontName, setSecondaryFont, getFullFontsList])

  return (
    <div className={styles.typographyPage}>
      <UploadFontModal
        fontType={fontType}
        isOpen={isUploadFontModalOpen}
        onChange={isOpen => setIsUploadFontModalOpen(isOpen)}
      />
      <WppTypography type="s-body" className={styles.description}>
        Detailed guidance about typography on the{' '}
        <a href="https://developers-ch-hulk.os-dev.io/" className={styles.link}>
          Developer Hub
        </a>{' '}
        provides all the information how to generate pallets, use external plugins and so on.
      </WppTypography>
      <WppCard size="l" className={styles.fontCard}>
        <WppTypography type="l-strong" slot="header">
          Font family
        </WppTypography>
        <div className={styles.cardBody}>
          <WppTypography type="s-body">
            Select theme font from the predefined ones or upload your custom one.
          </WppTypography>
          <div className={styles.actions}>
            <WppSelect
              required
              value={selectedFontName}
              className={styles.singleSelect}
              onWppChange={handleSelectChange}
              labelConfig={{ text: 'Primary font family' }}
              placeholder="Select primary font family"
            >
              {customFont && (
                <WppListItem value={customFont.name} key={customFont.name}>
                  <p slot="label">{upperFirst(customFont.name)}</p>
                </WppListItem>
              )}
              {themesFonts.map(fontName => (
                <WppListItem value={fontName.name} key={fontName.name}>
                  <p slot="label">{upperFirst(fontName.name)}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <WppTypography type="s-midi" className={styles.orText}>
              or
            </WppTypography>
            <WppActionButton
              className={styles.uploadFontButton}
              onClick={() => {
                setIsUploadFontModalOpen(true)
                setFontType('primary')
              }}
            >
              Upload font
              <WppIconUpload slot="icon-start" />
            </WppActionButton>
          </div>
          <WppDivider className={styles.fontDivider} />
          <div className={styles.secondaryFontWrapper}>
            <WppToggle
              required
              className={styles.secondaryFontToggle}
              labelConfig={{
                text: 'Enable Secondary font family ',
                icon: 'wpp-icon-info',
                description: 'This font family is only used for 5XL, 4XL, and 3XL sizes.',
              }}
              checked={isSecondaryFontEnabled}
              onWppChange={handleSecondaryFontSwitchChange}
            />
          </div>
          {isSecondaryFontEnabled && (
            <div className={styles.actions}>
              <WppSelect
                required
                value={selectedSecondaryFontName}
                className={styles.singleSelect}
                onWppChange={handleSecondarySelectChange}
                labelConfig={{ text: 'Secondary font family' }}
                placeholder="Select secondary font family"
              >
                {secondaryCustomFont && (
                  <WppListItem value={secondaryCustomFont.name} key={secondaryCustomFont.name}>
                    <p slot="label">{upperFirst(secondaryCustomFont.name)}</p>
                  </WppListItem>
                )}
                {themesFonts.map(fontName => (
                  <WppListItem value={fontName.name} key={fontName.name}>
                    <p slot="label">{upperFirst(fontName.name)}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppTypography type="s-midi" className={styles.orText}>
                or
              </WppTypography>
              <WppActionButton
                className={styles.uploadFontButton}
                onClick={() => {
                  setIsUploadFontModalOpen(true)
                  setFontType('secondary')
                }}
              >
                Upload font
                <WppIconUpload slot="icon-start" />
              </WppActionButton>
            </div>
          )}
        </div>
      </WppCard>

      {TYPOGRAPHY_TYPES_SECTIONS.map(section => (
        <TypographyTypeSection
          key={section.title + section.fieldPath}
          title={section.title}
          fieldPath={section.fieldPath}
          sections={section.sections}
          usageDetails={section.usageDetails}
          selectedFontName={selectedFontName}
          selectedSecondaryFontName={selectedSecondaryFontName}
        />
      ))}
    </div>
  )
}
