import React from 'react'

import { WppTopbar, WppTypography } from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import logoUrl from 'assets/logo.svg'

import styles from './Header.module.scss'

export const Header = React.memo(() => {
  const navigate = useNavigate()

  const handleTopBarChange = (event: CustomEvent) => {
    const value = event.detail.value
    navigate(`/${value}`)
  }

  const handleTopBarClick = () => {
    navigate('/home')
  }

  return (
    <WppTopbar className={styles.topbar} navigation={[]} onWppChange={handleTopBarChange}>
      <div slot="app" className={styles.app} onClick={handleTopBarClick}>
        <img src={logoUrl} alt="logo" />
        <WppTypography className={styles.title} type="m-strong" tag="h3">
          Theme Builder
        </WppTypography>
      </div>
    </WppTopbar>
  )
})
