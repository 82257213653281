import React, { useCallback, useState } from 'react'

import { Theme } from '@platform-ui-kit/components-library'
import { WppToastContainer } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Header } from 'components/Header/Header'
import { HomePage } from 'pages/home/HomePage'
import { ThemeFont } from 'pages/manage-theme/components/Typography/types'
import { THEME_OPTIONS } from 'pages/manage-theme/consts'
import { ManageThemePage } from 'pages/manage-theme/ManageThemePage'
import { CreateTheme, CustomFont, MayBeNull } from 'pages/manage-theme/types'
import { getInitialValues } from 'pages/manage-theme/utils'
import { PreviewPage } from 'pages/preview/PreviewPage'

import styles from './App.module.scss'
import { AppContext } from './context'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */

  const { osContext } = useOs()
  const [createTheme, setCreateTheme] = useState<CreateTheme>(getInitialValues())
  const [customFont, setCustomFont] = useState<MayBeNull<CustomFont>>(null)
  const [fontTypes, setFontTypes] = useState({})
  const [secondaryCustomFont, setSecondaryCustomFont] = useState<MayBeNull<CustomFont>>(null)
  const [secondaryFont, setSecondaryFont] = useState<MayBeNull<ThemeFont>>(null)
  const [savedCreateTheme, setSavedCreateTheme] = useState<CreateTheme | null>(null)
  const [usageDetails, setUsageDetails] = useState<any>(null)
  const [jsonData, setJsonData] = useState<MayBeNull<Theme>>(null)
  const [previewId, setPreviewId] = useState<MayBeNull<string>>(null)

  const memoizedSetCreateTheme = useCallback(
    (newTheme: CreateTheme) => {
      setCreateTheme(newTheme)
    },
    [setCreateTheme],
  )

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <AppContext.Provider
        value={{
          createTheme,
          setCreateTheme: memoizedSetCreateTheme,
          savedCreateTheme,
          setSavedCreateTheme,
          usageDetails,
          setUsageDetails,
          jsonData,
          setJsonData,
          customFont,
          setCustomFont,
          secondaryFont,
          setSecondaryFont,
          secondaryCustomFont,
          setSecondaryCustomFont,
          previewId,
          setPreviewId,
          fontTypes,
          setFontTypes,
        }}
      >
        <div className={styles.container}>
          <Header />
          <WppToastContainer maxToastsToDisplay={5} />
          <div className={styles.pageContent}>
            <Routes>
              <Route path="/home" element={<HomePage />} />
              <Route path="theme/edit" element={<ManageThemePage themeOption={THEME_OPTIONS.edit} />} />
              <Route path="theme/create" element={<ManageThemePage themeOption={THEME_OPTIONS.create} />} />
              <Route path="theme/preview" element={<PreviewPage />} />
              <Route index element={<Navigate to="/home" />} />
            </Routes>
          </div>
        </div>
      </AppContext.Provider>
    </BrowserRouter>
  )
}
