import React, { useEffect } from 'react'

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'

import { useAppContext } from 'app/context'
import { PaletteSection } from 'pages/manage-theme/components/Colors/components/PaletteSection/PaletteSection'
import { COLOR_PALETTES } from 'pages/manage-theme/consts'
import { CreateTheme } from 'pages/manage-theme/types'

import styles from './ColorsSection.module.scss'

export const ColorsSection: React.FC = () => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()

  const { setCreateTheme, createTheme } = appContext

  useEffect(() => {
    setCreateTheme({ ...formik.values, name: createTheme.name, font: createTheme.font })
  }, [createTheme.name, createTheme.font, formik.values, setCreateTheme])

  return (
    <div className={styles.colorsPage}>
      <WppTypography type="s-body" className={styles.description}>
        Detailed guidance about colors on the{' '}
        <a href="https://developers-ch-hulk.os-dev.io/" className={styles.link}>
          Developer Hub
        </a>{' '}
        provides all the information how to generate pallets, use external plugins and so on.
      </WppTypography>

      {COLOR_PALETTES.map(palette => (
        <PaletteSection
          key={palette.title}
          title={palette.title}
          description={palette.description}
          fields={palette.fields}
          fieldPath={palette.fieldPath}
          sections={palette.sections}
        />
      ))}
    </div>
  )
}
