import React from 'react'

import { InputTypes } from '@platform-ui-kit/components-library'
import { WppSelect } from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

export const FormSelect: React.FC<
  React.PropsWithChildren<{ name: string; label: string; placeholder: string; className?: string; type?: InputTypes }>
> = ({ name, className, children, placeholder }) => {
  const formik = useFormikContext()

  return (
    <WppSelect
      name={name}
      value={get(formik.values, name)}
      onWppChange={formik.handleChange}
      className={className}
      placeholder={placeholder}
      key={get(formik.values, name)}
    >
      {children}
    </WppSelect>
  )
}
