import React, { useState } from 'react'

import { FileItemType, FileUploadEventDetail } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppButton,
  WppTypography,
  WppModal,
  WppFileUpload,
} from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from 'app/context'

import styles from './ImportJsonModal.module.scss'

export const ImportJsonModal: React.FC<{ isOpen: boolean; onChange: (isOpen: boolean) => void }> = ({
  isOpen,
  onChange,
}) => {
  const appContext = useAppContext()

  const [file, setFile] = useState<FileItemType>()
  const navigate = useNavigate()

  const handleCloseModal = () => {
    onChange(false)
  }

  const handleUploadFile = () => {
    const reader = new FileReader()
    reader.onload = async (e: any) => {
      const text = e.target.result

      appContext.setJsonData(JSON.parse(text))
      setTimeout(() => {
        navigate('/theme/edit')
      }, 10)
    }
    reader.readAsText(file as Blob, 'utf-8')
  }

  const handleFileChange = (event: CustomEvent<FileUploadEventDetail>) => {
    if (Array.isArray(event.detail.value)) {
      setFile(event.detail.value[0])
    }
  }

  return (
    <WppModal className={styles.modal} size="m" open={isOpen} onWppModalClose={handleCloseModal}>
      <WppTypography slot="header" type="xl-heading" className={styles.title}>
        Upload JSON
      </WppTypography>
      <div slot="body">
        <WppTypography type="s-body" className={styles.description}>
          Select the .json file of the one existing theme you want to modify.
        </WppTypography>
        <WppFileUpload
          className={styles.fileUpload}
          multiple={false}
          accept={['.json']}
          onWppChange={handleFileChange}
        />
      </div>
      <div slot="actions" className={styles.actions}>
        <WppActionButton variant="secondary" onClick={handleCloseModal}>
          Cancel
        </WppActionButton>
        <WppButton variant="primary" size="s" onClick={handleUploadFile}>
          Upload
        </WppButton>
      </div>
    </WppModal>
  )
}
