import React, { useState } from 'react'

import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppButton,
  WppTypography,
  WppModal,
  WppInput,
} from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from 'app/context'

import styles from './CreateNewThemeModal.module.scss'
import { getInitialValues } from '../../../manage-theme/utils'

export const CreateNewThemeModal: React.FC<{ isOpen: boolean; onChange: (isOpen: boolean) => void }> = ({
  isOpen,
  onChange,
}) => {
  const [themeName, setThemeName] = useState('')
  const navigate = useNavigate()
  const appContext = useAppContext()

  const handleCloseModal = () => {
    onChange(false)
  }

  const handleInputChange = (event: CustomEvent<InputChangeEventDetail>) => setThemeName(event.detail.value as string)

  const handleSubmitThemeName = () => {
    appContext.setCreateTheme({ ...getInitialValues(), name: themeName })
    appContext.setSavedCreateTheme(null)
    appContext.setJsonData(null)
    navigate('/theme/create')
  }

  return (
    <WppModal className={styles.modal} size="s" open={isOpen} onWppModalClose={handleCloseModal}>
      <WppTypography slot="header" type="xl-heading" class={styles.title}>
        Create new theme
      </WppTypography>
      <div slot="body">
        <WppInput
          required
          labelConfig={{ text: 'Theme name' }}
          name="new-theme-name"
          id="new-theme-name"
          onWppChange={handleInputChange}
        />
      </div>
      <div slot="actions" className={styles.actions}>
        <WppActionButton variant="secondary" onClick={handleCloseModal}>
          Cancel
        </WppActionButton>
        <WppButton variant="primary" size="s" onClick={handleSubmitThemeName} disabled={themeName.length === 0}>
          Continue
        </WppButton>
      </div>
    </WppModal>
  )
}
