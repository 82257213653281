import React, { useEffect, useState } from 'react'

import {
  WppAccordion,
  WppActionButton,
  WppCard,
  WppExpandableCard,
  WppIconColor,
  WppIconTrash,
  WppDivider,
  WppIconPlus,
  WppTooltip,
  WppIconReset,
  WppIconBookInformation,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get, isEqual, size, keys } from 'lodash'

import { useAppContext } from 'app/context'
import { SketchExample } from 'components/ColorPicker/ColorPicker'
import { FormInput } from 'components/FormInput/FormInput'
import { CreateTheme, UsageDetails } from 'pages/manage-theme/types'
import { getBoxShadowFieldPath, getBoxShadowStyleForValues, getDefaultBoxShadowValues } from 'pages/manage-theme/utils'

import styles from './BoxShadow.module.scss'

const OFFSET_PARAMS = ['x', 'y', 'blur', 'spread']
const COLOR_PARAMS = ['color', 'opacity']

const DropShadowAccordion: React.FC<{
  inputName: string
  index: number
  sectionId: number
  onDeleteClick: (sectionId: number) => void
}> = ({ inputName, index, sectionId, onDeleteClick }) => {
  const formik = useFormikContext<CreateTheme>()
  const handleColorChange = (inputField: string, color: string) => {
    formik.setFieldValue(inputField, color)
  }

  return (
    <WppAccordion text={`Drop Shadow ${index}`} size="m" className={styles.accordion} withDivider>
      <WppActionButton variant="secondary" slot="actions" onClick={() => onDeleteClick(sectionId)}>
        <WppIconTrash slot="icon-start" />
      </WppActionButton>
      <div className={styles.shadowActions}>
        <div className={styles.shadowPosition}>
          {OFFSET_PARAMS.map(field => (
            <FormInput
              key={`${inputName}.${field}`}
              name={`${inputName}.${field}`}
              label={field}
              placeholder="Enter number"
              className={styles.input}
            />
          ))}
        </div>
        <div className={styles.shadowColorAndOpacity}>
          {COLOR_PARAMS.map(field => (
            <div className={styles.shadowColorWrapper} key={`${inputName}.${field}`}>
              <FormInput
                name={`${inputName}.${field}`}
                label={field}
                placeholder={field === 'color' ? 'Enter hex value' : 'Enter number'}
                type={field === 'color' ? 'text' : 'number'}
                className={styles.input}
              >
                {field === 'color' && <WppIconColor slot="icon-start" style={{ display: 'none' }} />}
              </FormInput>
              {field === 'color' && (
                <div className={styles.colorPickerWrapper}>
                  <SketchExample
                    value={get(formik.values, `${inputName}.${field}`, '') as string}
                    onChange={data => handleColorChange(`${inputName}.${field}`, data)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </WppAccordion>
  )
}

export const BoxShadow: React.FC<{ type: string; usageDetails: UsageDetails }> = ({ type, usageDetails }) => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()

  const sectionsNumber = keys(get(formik.initialValues, `objectStyles.boxShadow.${type}`, {})).length
  const [shadowSectionsIds, setShadowSectionsIds] = useState<number[]>(
    Array.from({ length: sectionsNumber }, (_, index) => index),
  )
  const [lastSectionId, setLastSectionId] = useState<number>(sectionsNumber)

  useEffect(() => {
    const sectionsNumber = keys(get(formik.initialValues, `objectStyles.boxShadow.${type}`, {})).length
    setShadowSectionsIds(Array.from({ length: sectionsNumber }, (_, index) => index))
    setLastSectionId(sectionsNumber)
  }, [formik.initialValues, type])

  const inputName = getBoxShadowFieldPath(type)
  const boxShadow = getBoxShadowStyleForValues(formik.values, type)

  const isChanged = !isEqual(
    get(formik.initialValues, `objectStyles.boxShadow.${type}`, {}),
    get(formik.values, `objectStyles.boxShadow.${type}`, {}),
  )

  const handleAddNewShadowSection = () => {
    const newSectionId = lastSectionId + 1

    setShadowSectionsIds([...shadowSectionsIds, newSectionId])
    setLastSectionId(newSectionId)
    formik.setFieldValue(`${inputName}.${newSectionId}`, getDefaultBoxShadowValues())
  }

  const handleDeleteClick = (sectionId: number) => {
    setShadowSectionsIds(shadowSectionsIds.filter(i => i !== sectionId))
    formik.setFieldValue(`${inputName}.${sectionId}`, null)
  }

  const handleResetField = () => {
    const sectionsNumber = size(get(formik.initialValues, `objectStyles.boxShadow.${type}`, []))
    setShadowSectionsIds(Array.from({ length: sectionsNumber }, (_, index) => index))
    setLastSectionId(sectionsNumber)
    formik.setFieldValue(inputName, get(formik.initialValues, inputName))
  }

  const boxShadowStyle = {
    '--custom-box-shadow': boxShadow,
  }

  return (
    <WppExpandableCard header={type.toUpperCase()} size="l" className={styles.item}>
      <div slot="actions">
        {isChanged && (
          <WppTooltip text="Reset all changes" className={styles.resetBtn} onClick={handleResetField}>
            <WppActionButton variant="secondary">
              <WppIconReset slot="icon-start" />
            </WppActionButton>
          </WppTooltip>
        )}
        <WppTooltip text="See usage details" onClick={() => appContext.setUsageDetails(usageDetails)}>
          <WppActionButton variant="secondary">
            <WppIconBookInformation slot="icon-start" />
          </WppActionButton>
        </WppTooltip>
      </div>
      <div className={styles.body}>
        <WppCard className={styles.card} variant="secondary">
          <WppCard className={styles.preview} style={boxShadowStyle} />
        </WppCard>

        <div className={styles.actions}>
          <WppDivider />
          {shadowSectionsIds.map((sectionId, index) => [
            <DropShadowAccordion
              key={`${inputName}.${sectionId}`}
              inputName={`${inputName}.${sectionId}`}
              sectionId={sectionId}
              index={index + 1}
              onDeleteClick={sectionId => handleDeleteClick(sectionId)}
            />,
          ])}
          <div className={styles.addNewShadowWrapper}>
            <WppActionButton variant="primary" onClick={handleAddNewShadowSection}>
              Add drop shadow
              <WppIconPlus slot="icon-end" />
            </WppActionButton>
          </div>
        </div>
      </div>
    </WppExpandableCard>
  )
}
