import React, { FC, PropsWithChildren } from 'react'

import { WppCard, WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'
import { get } from 'lodash'

import { useAppContext } from 'app/context'

import styles from './RadiusUsageDetails.module.scss'

export const RadiusUsageDetails: FC<PropsWithChildren<{ title: string; type: string }>> = ({
  title,
  type,
  children,
}) => {
  const appContext = useAppContext()
  const radius = get(appContext.createTheme, `objectStyles.border.radius.${type}`)

  return (
    <div className={styles.body}>
      <WppTypography type="s-strong" className={styles.header}>
        {title}
      </WppTypography>
      <WppCard className={styles.card} variant="secondary">
        <div className={styles.preview} style={{ borderRadius: `${(radius || '').replace(/px/g, '')}px` }} />
      </WppCard>
      <WppDivider className={styles.divider} />
      {children}
    </div>
  )
}
